import { readonly, ref, watch, WatchStopHandle } from 'vue';
import { useSound } from '@vueuse/sound';

const audioListen = ref<ReturnType<typeof useSound>>();
const playDisabled = ref(false);
let watched: WatchStopHandle | null = null;

function useListenAudio() {
    const hasAudioBeenPlayedAtLeastOneTime = ref(false);
    const setHasAudioBeenPlayedAtLeastOneTime = (value: boolean) => {
        hasAudioBeenPlayedAtLeastOneTime.value = value;
    };

    function listen(sound: ReturnType<typeof useSound>, cantBeInterrupt: boolean) {
        if (!playDisabled.value) {
            audioListen.value?.stop();
            if (watched) {
                watched();
            }

            playDisabled.value = cantBeInterrupt;
            audioListen.value = sound;
            audioListen.value.play();

            watched = watch(sound.isPlaying, (isPlaying) => {
                if (!isPlaying && playDisabled.value) {
                    playDisabled.value = false;
                }
            });
        }
    }

    function stopListen() {
        audioListen.value?.stop();
    }

    return {
        audioListen,
        playDisabled,
        listen,
        stopListen,
        setHasAudioBeenPlayedAtLeastOneTime,
        hasAudioBeenPlayedAtLeastOneTime: readonly(hasAudioBeenPlayedAtLeastOneTime),
    };
}

export default useListenAudio;
