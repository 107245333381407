import { createRouter, createWebHistory, NavigationGuardWithThis, RouteRecordRaw } from 'vue-router';
import { currentTask, loadCurrentTaskAndSerie } from '@/composables/useSession';
import useDraggableBox from '@/composables/useDraggableBox';
import useCalculate from '@/composables/useCalculate';

const { resetDraggableBox } = useDraggableBox();
const { resetCalculator } = useCalculate();
const beforeEnterTaskGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
    loadCurrentTaskAndSerie(); // ensure the current task is up-to-date
    if (currentTask.value) {
        if (to.name === `tasks.${currentTask.value.name}`) {
            return next();
        }
        return next({ name: `tasks.${currentTask.value.name}` });
    }
    return next({ name: 'homepage' });
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'homepage',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    },
    {
        path: '/tasks/',
        children: [
            {
                path: 'pem-ide',
                name: 'tasks.pem-ide',
                component: () => import(/* webpackChunkName: "pem-ide" */ '../views/PEM/PemIdeView.vue'),
            },
            {
                path: 'pem-son',
                name: 'tasks.pem-son',
                component: () => import(/* webpackChunkName: "pem-son" */ '../views/PEM/PemSonView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'pem-enr',
                name: 'tasks.pem-enr',
                component: () => import(/* webpackChunkName: "pem-enr" */ '../views/PEM/PemEnrView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'pem-dra',
                name: 'tasks.pem-dra',
                component: () => import(/* webpackChunkName: "pem-dra" */ '../views/PEM/PemDraView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'pem-cal',
                name: 'tasks.pem-cal',
                component: () => import(/* webpackChunkName: "pem-cal" */ '../views/PEM/PemCalView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-start',
                name: 'tasks.mat-start',
                component: () => import(/* webpackChunkName: "mat-start" */ '../views/MAT/MatStartView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-div',
                name: 'tasks.mat-div',
                component: () => import(/* webpackChunkName: "mat-div" */ '../views/MAT/MatDivView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-ecr',
                name: 'tasks.mat-ecr',
                component: () => import(/* webpackChunkName: "mat-ecr" */ '../views/MAT/MatEcrView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-flo',
                name: 'tasks.mat-flo',
                component: () => import(/* webpackChunkName: "mat-flo" */ '../views/MAT/MatFloView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-sym',
                name: 'tasks.mat-sym',
                component: () => import(/* webpackChunkName: "mat-sym" */ '../views/MAT/MatSymView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'mat-tax',
                name: 'tasks.mat-tax',
                component: () => import(/* webpackChunkName: "mat-tax" */ '../views/MAT/MatTaxView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-anc-1',
                name: 'tasks.aco-anc-1',
                component: () => import(/* webpackChunkName: "aco-anc-1" */ '../views/ACO/AcoAnc1View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-anc-2',
                name: 'tasks.aco-anc-2',
                component: () => import(/* webpackChunkName: "aco-anc-2" */ '../views/ACO/AcoAnc2View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-fet-1',
                name: 'tasks.aco-fet-1',
                component: () => import(/* webpackChunkName: "aco-fet-1" */ '../views/ACO/AcoFet1View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-fet-2',
                name: 'tasks.aco-fet-2',
                component: () => import(/* webpackChunkName: "aco-fet-2" */ '../views/ACO/AcoFet2View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-fet-3',
                name: 'tasks.aco-fet-3',
                component: () => import(/* webpackChunkName: "aco-fet-3" */ '../views/ACO/AcoFet3View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-fet-4',
                name: 'tasks.aco-fet-4',
                component: () => import(/* webpackChunkName: "aco-fet-4" */ '../views/ACO/AcoFet4View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-fet-5',
                name: 'tasks.aco-fet-5',
                component: () => import(/* webpackChunkName: "aco-fet-5" */ '../views/ACO/AcoFet5View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-nve-1',
                name: 'tasks.aco-nve-1',
                component: () => import(/* webpackChunkName: "aco-nve-1" */ '../views/ACO/AcoNve1View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-nve-2',
                name: 'tasks.aco-nve-2',
                component: () => import(/* webpackChunkName: "aco-nve-2" */ '../views/ACO/AcoNve2View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-nve-3',
                name: 'tasks.aco-nve-3',
                component: () => import(/* webpackChunkName: "aco-nve-3" */ '../views/ACO/AcoNve3View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-par-1',
                name: 'tasks.aco-par-1',
                component: () => import(/* webpackChunkName: "aco-par-1" */ '../views/ACO/AcoPar1View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-par-2',
                name: 'tasks.aco-par-2',
                component: () => import(/* webpackChunkName: "aco-par-2" */ '../views/ACO/AcoPar2View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-par-3',
                name: 'tasks.aco-par-3',
                component: () => import(/* webpackChunkName: "aco-par-3" */ '../views/ACO/AcoPar3View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'aco-par-4',
                name: 'tasks.aco-par-4',
                component: () => import(/* webpackChunkName: "aco-par-4" */ '../views/ACO/AcoPar4View.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'apo-start',
                name: 'tasks.apo-start',
                component: () => import(/* webpackChunkName: "apo-start" */ '../views/APO/ApoStartView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'apo-nve',
                name: 'tasks.apo-nve',
                component: () => import(/* webpackChunkName: "apo-nve" */ '../views/APO/ApoNveView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'apo-par',
                name: 'tasks.apo-par',
                component: () => import(/* webpackChunkName: "apo-par" */ '../views/APO/ApoParView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
            {
                path: 'end-task',
                name: 'tasks.end-task',
                component: () => import(/* webpackChunkName: "end-task" */ '../views/END/EndTaskView.vue'),
                beforeEnter: beforeEnterTaskGuard,
            },
        ],
    },
    // TODO: remove the routes below when app ready for staging
    // TEST routes
    {
        path: '/PEM-IDE',
        name: 'PEM-IDE',
        component: () => import(/* webpackChunkName: "PME-IDE-TEST" */ '../views/PEM/PemIdeView.vue'),
    },
    {
        path: '/PEM-SON',
        name: 'PEM-SON',
        component: () => import(/* webpackChunkName: "PEM-SON-TEST" */ '../views/PEM/PemSonView.vue'),
    },
    {
        path: '/PEM-ENR',
        name: 'PEM-ENR',
        component: () => import(/* webpackChunkName: "PEM-ENR-TEST" */ '../views/PEM/PemEnrView.vue'),
    },
    {
        path: '/PEM-DRA',
        name: 'PEM-DRA',
        component: () => import(/* webpackChunkName: "PEM-DRA-TEST" */ '../views/PEM/PemDraView.vue'),
    },
    {
        path: '/PEM-CAL',
        name: 'PEM-CAL',
        component: () => import(/* webpackChunkName: "PEM-CAL-TEST" */ '../views/PEM/PemCalView.vue'),
    },
    {
        path: '/MAT-START',
        name: 'MAT-START',
        component: () => import(/* webpackChunkName: "MAT-START-TEST" */ '../views/MAT/MatStartView.vue'),
    },
    {
        path: '/MAT-DIV',
        name: 'MAT-DIV',
        component: () => import(/* webpackChunkName: "MAT-DIV-TEST" */ '../views/MAT/MatDivView.vue'),
    },
    {
        path: '/MAT-ECR',
        name: 'MAT-ECR',
        component: () => import(/* webpackChunkName: "MAT-ECR-TEST" */ '../views/MAT/MatEcrView.vue'),
    },
    {
        path: '/MAT-TAX',
        name: 'MAT-TAX',
        component: () => import(/* webpackChunkName: "MAT-TAX-TEST" */ '../views/MAT/MatTaxView.vue'),
    },
    {
        path: '/MAT-SYM',
        name: 'MAT-SYM',
        component: () => import(/* webpackChunkName: "MAT-SYM-TEST" */ '../views/MAT/MatSymView.vue'),
    },
    {
        path: '/MAT-FLO',
        name: 'MAT-FLO',
        component: () => import(/* webpackChunkName: "MAT-FLO-TEST" */ '../views/MAT/MatFloView.vue'),
    },
    {
        path: '/ACO-ANC-1',
        name: 'ACO-ANC-1',
        component: () => import(/* webpackChunkName: "ACO-ANC-1-TEST" */ '../views/ACO/AcoAnc1View.vue'),
    },
    {
        path: '/ACO-ANC-2',
        name: 'ACO-ANC-2',
        component: () => import(/* webpackChunkName: "ACO-ANC-2-TEST" */ '../views/ACO/AcoAnc2View.vue'),
    },
    {
        path: '/ACO-FET-1',
        name: 'ACO-FET-1',
        component: () => import(/* webpackChunkName: "ACO-FET-1-TEST" */ '../views/ACO/AcoFet1View.vue'),
    },
    {
        path: '/ACO-FET-2',
        name: 'ACO-FET-2',
        component: () => import(/* webpackChunkName: "ACO-FET-2-TEST" */ '../views/ACO/AcoFet2View.vue'),
    },
    {
        path: '/ACO-FET-3',
        name: 'ACO-FET-3',
        component: () => import(/* webpackChunkName: "ACO-FET-3-TEST" */ '../views/ACO/AcoFet3View.vue'),
    },
    {
        path: '/ACO-FET-4',
        name: 'ACO-FET-4',
        component: () => import(/* webpackChunkName: "ACO-FET-4-TEST" */ '../views/ACO/AcoFet4View.vue'),
    },
    {
        path: '/ACO-FET-5',
        name: 'ACO-FET-5',
        component: () => import(/* webpackChunkName: "ACO-FET-5-TEST" */ '../views/ACO/AcoFet5View.vue'),
    },
    {
        path: '/ACO-NVE-1',
        name: 'ACO-NVE-1',
        component: () => import(/* webpackChunkName: "ACO-NVE-1-TEST" */ '../views/ACO/AcoNve1View.vue'),
    },
    {
        path: '/ACO-NVE-2',
        name: 'ACO-NVE-2',
        component: () => import(/* webpackChunkName: "ACO-NVE-2-TEST" */ '../views/ACO/AcoNve2View.vue'),
    },
    {
        path: '/ACO-NVE-3',
        name: 'ACO-NVE-3',
        component: () => import(/* webpackChunkName: "ACO-NVE-3-TEST" */ '../views/ACO/AcoNve3View.vue'),
    },
    {
        path: '/ACO-PAR-1',
        name: 'ACO-PAR-1',
        component: () => import(/* webpackChunkName: "ACO-PAR-1-TEST" */ '../views/ACO/AcoPar1View.vue'),
    },
    {
        path: '/ACO-PAR-2',
        name: 'ACO-PAR-2',
        component: () => import(/* webpackChunkName: "ACO-PAR-2-TEST" */ '../views/ACO/AcoPar2View.vue'),
    },
    {
        path: '/ACO-PAR-3',
        name: 'ACO-PAR-3',
        component: () => import(/* webpackChunkName: "ACO-PAR-3-TEST" */ '../views/ACO/AcoPar3View.vue'),
    },
    {
        path: '/ACO-PAR-4',
        name: 'ACO-PAR-4',
        component: () => import(/* webpackChunkName: "ACO-PAR-4-TEST" */ '../views/ACO/AcoPar4View.vue'),
    },
    {
        path: '/END-TASK',
        name: 'END-TASK',
        component: () => import(/* webpackChunkName: "END-TASK-TEST" */ '../views/END/EndTaskView.vue'),
    },
    {
        path: '/APO-START',
        name: 'APO-START',
        component: () => import(/* webpackChunkName: "APO-START-TEST" */ '../views/APO/ApoStartView.vue'),
    },
    {
        path: '/APO-NVE',
        name: 'APO-NVE',
        component: () => import(/* webpackChunkName: "APO-NVE-TEST" */ '../views/APO/ApoNveView.vue'),
    },
    {
        path: '/APO-PAR',
        name: 'APO-PAR',
        component: () => import(/* webpackChunkName: "APO-PAR-TEST" */ '../views/APO/ApoParView.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    resetDraggableBox();
    resetCalculator();

    // scroll to top of page at each route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 200);

    next();
});

export default router;
