import Task from '@/models/Task';
import TaskStatus from '@/models/TaskStatus';

export default class Serie {
    private readonly _name: string | null;
    private _currentTaskIndex: number;
    private readonly _tasks: Array<Task>;

    public constructor(tasks: Array<Task>, name: string | null = null) {
        this._currentTaskIndex = -1;
        this._tasks = tasks;
        this._name = name;
    }

    public isFinished(): boolean {
        return this._tasks.filter((task: Task) => task.status !== TaskStatus.FINISHED).length === 0;
    }

    public selectNextTask(): Task | null {
        const currentTask = this._tasks[this._currentTaskIndex] ?? null;
        if (currentTask && currentTask.status !== TaskStatus.FINISHED) {
            throw new Error('Impossible to select the next task until the current is completed');
        }
        this._currentTaskIndex += 1;
        return this.getCurrentTask();
    }

    public getNextTask(): Task | null {
        this.getCurrentTask();
        return this._tasks[this._currentTaskIndex + 1] ?? null;
    }

    public getCurrentTask(): Task | null {
        if (this._currentTaskIndex < 0) {
            this._currentTaskIndex += 1;
        }
        return this._tasks[this._currentTaskIndex] ?? null;
    }

    // Accessors

    get tasks(): Array<Task> {
        return this._tasks;
    }

    get name(): string | null {
        return this._name;
    }
}
