import useAxios from '@/composables/useAxios';
import TaskConfig from '@/interfaces/TaskConfig';
import { ref } from 'vue';
import { AxiosResponse } from 'axios';
import Session from '@/models/Session';
import Serie from '@/models/Serie';
import TaskType from '@/models/TaskType';
import BasicTask from '@/models/Tasks/BasicTask';
import CalcTask from '@/models/Tasks/CalcTask';
import Task from '@/interfaces/Task';
// eslint-disable-next-line import/no-named-default
import { default as TaskModel } from '@/models/Task';
import { useRouter } from 'vue-router';
import SessionStatus from '@/models/SessionStatus';
import { PresenceChannel } from 'laravel-echo';
// eslint-disable-next-line import/no-named-default
import { default as SessionInterface } from '@/interfaces/Session';
import Progress from '@/interfaces/Progress';
import ProgressStatus from '@/models/ProgressStatus';
import { useApmTransaction } from '@/composables/useElasticApmRum';
import FakeConfig from '../config/FakeConfig';

// define the login task before the session is loaded from the API
const session = ref<null | Session>(new Session('', [new Serie([new BasicTask('pem-ide', 'LOGIN page')], 'PEM')]));
const taskConfig = ref<null | TaskConfig>(null);
const fakeConfig = new FakeConfig();

// save the latest session status received from the broadcast channel
const currentSessionStatus = ref<null | SessionStatus>(null);
// save the latest progress status received from the broadcast channel
const currentProgressStatus = ref<null | ProgressStatus>(null);

// the currentSerie and currentTask variables are used to manage the navigation between task components
// these variables can differ from the session configuration because the user can join a session in progress
export const currentSerie = ref<Serie | null>();
export const currentTask = ref<TaskModel | null>();

/**
 * Set the current serie and task from the session configuration
 * The current serie and/or task can be different from the one in the session configuration
 * after the joinSession method is called when the user wants to recover its session status for example
 */
export function loadCurrentTaskAndSerie(): void {
    currentSerie.value = session.value?.getCurrentSerie();
    currentTask.value = session.value?.getCurrentTask();
}

export default function useSession() {
    const axios = useAxios();
    const router = useRouter();
    const { endTransaction, addLabels, currentTransaction } = useApmTransaction();

    /**
     * Converts an API task formatted in JSON to its related TaskModel
     */
    function mapApiTaskToTaskModel(apiTask: Task): TaskModel {
        if (apiTask.type === TaskType.BASIC_TASK) {
            return new BasicTask(apiTask.name, apiTask.name, apiTask.expectedResponseValue ?? undefined);
        }
        if (apiTask.type === TaskType.CALC_TASK) {
            if (!apiTask.calculus) {
                throw new Error(`CalcTask ${apiTask.name} has no calculus`);
            }
            return new CalcTask(apiTask.name, apiTask.description, apiTask.calculus);
        }

        throw new Error(`Unknown task type: ${apiTask.type}`);
    }

    /**
     * Converts an API task config formatted in JSON to its related Session model
     */
    function mapApiTaskConfigToSessionModel(apiTaskConfig: TaskConfig): Session {
        const sessionConfig = new Session(
            apiTaskConfig.session_id,
            apiTaskConfig.series.map(
                (series) =>
                    new Serie(
                        series.tasks.map((task) => mapApiTaskToTaskModel(task)),
                        series.name
                    )
            )
        );

        let currSerie: Serie | null = sessionConfig.getCurrentSerie();
        const currTask = apiTaskConfig.current_task;
        const currSerieName = apiTaskConfig.current_task.series?.toLowerCase();

        // find the current series and validate all the previous series tasks
        while (sessionConfig.getCurrentSerie()?.name?.toLowerCase() !== currSerieName) {
            // force check all tasks of the current series before the selection of the next one
            (sessionConfig.getCurrentSerie()?.tasks ?? []).forEach((task) => task.forceCheck());
            currSerie = sessionConfig.selectNextSerie();
        }

        // find the current task and validate all the previous task in the current serie
        while (currSerie?.getCurrentTask()?.name !== currTask.name) {
            currSerie?.getCurrentTask()?.forceCheck();
            currSerie?.selectNextTask();
        }

        return sessionConfig;
    }

    function resetSession(): void {
        // terminate correctly the current transaction before we load another session
        if (currentTransaction.value) {
            addLabels({ session: `${session.value?.id}` });
            endTransaction();
        }

        session.value = new Session('', [new Serie([new BasicTask('pem-ide', 'LOGIN page')], 'PEM')]);
        taskConfig.value = null;
        currentSessionStatus.value = null;

        loadCurrentTaskAndSerie();

        router.push({ name: 'homepage' });
    }

    function joinSession(studentCode: string): Promise<TaskConfig> {
        if (process.env.VUE_APP_FAKE_SESSION === 'true') {
            console.warn('Fake session mode: the session is not loaded from the API');
            return new Promise<TaskConfig>((resolve) => {
                const fakeTaskConfig = fakeConfig.config();
                resolve(fakeTaskConfig);
                console.log(fakeTaskConfig.session_id);
            }).then((fakeDataConfig) => {
                taskConfig.value = fakeDataConfig;
                session.value = mapApiTaskConfigToSessionModel(fakeDataConfig);

                // select the PEM pem-ide task by default
                currentSerie.value = session.value?.series[0] as Serie;
                currentTask.value = session.value?.series[0]?.tasks[0] as TaskModel;

                if (!currentSerie.value || !currentTask.value) {
                    throw new Error('The pem-ide task cannot be selected after the login');
                }

                return fakeDataConfig;
            });
        }
        return axios
            .post(`session/join`, {
                code: studentCode,
            })
            .then((response) => {
                const responseData = response.data;

                taskConfig.value = responseData;
                session.value = mapApiTaskConfigToSessionModel(responseData);
                currentSessionStatus.value = responseData.session_status;

                // select the PEM pem-ide task by default
                currentSerie.value = session.value?.series[0] as Serie;
                currentTask.value = session.value?.series[0]?.tasks[0] as TaskModel;

                if (!currentSerie.value || !currentTask.value) {
                    throw new Error('The pem-ide task cannot be selected after the login');
                }

                return responseData;
            });
    }

    function sendAnswer(
        seriesCode: string,
        taskCode: string,
        content?: string,
        file?: Blob,
        isStep = false
    ): Promise<AxiosResponse | boolean> {
        const route = isStep ? 'answer/step' : 'answer';
        const formData = new FormData();
        formData.append('series_code', seriesCode);
        formData.append('task_code', taskCode);
        if (file) {
            formData.append('file', file);
        }
        // a content is required if no file is provided
        if (content || !file) {
            formData.append('content', content ?? '');
        }

        let response: Promise<AxiosResponse | boolean> = axios.post(route, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (process.env.VUE_APP_FAKE_SESSION === 'true') {
            console.warn('Fake session mode: the answer is not sent to the server');
            response = response.catch(() => false);
        }

        return response;
    }

    /**
     * The goal of this method is to centralize the listeners registration for the presence channel for the session
     */
    function registerSessionChannelListeners(channel: PresenceChannel): void {
        channel.listen('.updated', ({ model: sessionData }: { model: SessionInterface }) => {
            currentSessionStatus.value = sessionData.status;
        });
        channel.listen('.progress.updated', ({ model: progress }: { model: Progress }) => {
            if (progress.student_id === taskConfig.value?.student_id) {
                if (
                    progress.task_code === currentTask.value?.name &&
                    progress.series_code === currentSerie.value?.name
                ) {
                    currentProgressStatus.value = progress.status;
                }
            }
        });
    }

    return {
        currentSerie,
        currentTask,
        session,
        currentSessionStatus,
        currentProgressStatus,
        taskConfig,
        loadCurrentTaskAndSerie,
        joinSession,
        sendAnswer,
        resetSession,
        registerSessionChannelListeners,
    };
}
