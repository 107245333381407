import { ApmBase, Span, SpanOptions, Transaction, TransactionOptions } from '@elastic/apm-rum';
import { ref, inject, Ref, onMounted, readonly } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import StatusLabel from '@/models/ElasticApmRum/Labels/StatusLabel';
import TransactionType from '@/models/ElasticApmRum/TransactionType';
import { onBeforeRouteLeave } from 'vue-router';

// global vars shared for all components because there is at most one transaction or span at the time
const currentTransaction = ref<Transaction | undefined>();
const currentSpan = ref<Span | undefined>();
const additionalSpans: { [id: string]: Span } = {};
const allSpanNamesUsedInCurrentTransaction: string[] = [];
let isAPMObserversInitialized = false;

export function useApmTransaction() {
    const apmService = inject<ApmBase | undefined>('$apm');

    if (!apmService) {
        console.warn('APM service not correctly initialized');
    }

    if (process.env.VUE_APP_DEBUG === 'true' && !isAPMObserversInitialized) {
        apmService?.observe('transaction:start', (transaction) => {
            if (transaction.type === TransactionType.TASK) {
                console.log('start', transaction);
            }
        });

        apmService?.observe('transaction:end', (transaction) => {
            if (transaction.type === TransactionType.TASK) {
                console.log('end', transaction);
            }
        });

        isAPMObserversInitialized = true;
    }

    /**
     * Starts a new transaction only if there is no transaction currently in progress.
     */
    function startTransaction(name: string, type: string, options?: TransactionOptions): Ref<Transaction | undefined> {
        if (!currentTransaction.value) {
            currentTransaction.value = apmService?.startTransaction(name, type, options);

            // block the transaction to ensure it will not be interrupted until we decide to close it
            const newTransaction: { block(val: boolean): void } = currentTransaction.value as never;
            newTransaction?.block(true);

            allSpanNamesUsedInCurrentTransaction.splice(0, allSpanNamesUsedInCurrentTransaction.length);
        } else {
            console.warn('No transaction has been started because a transaction is already in progress.');
        }
        return currentTransaction;
    }

    /**
     * Add labels to the current transaction
     */
    function addLabels(labels: Labels): void {
        if (currentTransaction.value) {
            currentTransaction.value.addLabels(labels);
        } else {
            throw new Error('A transaction must be started to add labels');
        }
    }

    /**
     * Add a mark to the current transaction.
     * The key should be unique for the current transaction.
     */
    function addMark(key: string): void {
        if (currentTransaction.value) {
            currentTransaction.value.mark(key);
        } else {
            throw new Error('A transaction must be started to add a mark');
        }
    }

    function formatSpanNameForCurrentTransaction(name: string): string {
        /* const countSimilarSpanNames = allSpanNamesUsedInCurrentTransaction.filter((spanName) =>
            spanName.includes(name)
        ).length;
        return `${name}_${countSimilarSpanNames + 1}`; */
        return name;
    }

    /**
     * Starts a span in the current transaction with a unique id.
     * You need to store the returned id to be able to stop thi span later with the method stopSpanWithId.
     */
    function startSpanWithId(name: string, type: string, labels?: Labels, options?: SpanOptions): string {
        if (currentTransaction.value) {
            const newName = formatSpanNameForCurrentTransaction(name);
            const span = currentTransaction.value?.startSpan(newName, type, options);
            if (span) {
                if (labels) {
                    span.addLabels(labels);
                }
                const spanId = uuidv4();
                additionalSpans[spanId] = span;
                allSpanNamesUsedInCurrentTransaction.push(newName);
                return spanId;
            }
            throw new Error('Impossible to generate a span for the current transaction');
        } else {
            throw new Error(
                'Impossible to add a Span if there is no active transaction or a span already in progress.'
            );
        }
    }

    function stopSpanWithId(spanId: string, labels?: Labels): void {
        if (additionalSpans[spanId]) {
            if (labels) {
                additionalSpans[spanId].addLabels(labels);
            }
            additionalSpans[spanId].end();
            delete additionalSpans[spanId];
        }
    }

    function getSpanById(spanId: string): Span | undefined {
        return additionalSpans[spanId];
    }

    /**
     * Starts a span in the current transaction. This is the default span. Only one can be created at once.
     */
    function startSpan(name: string, type: string, labels?: Labels, options?: SpanOptions): void {
        if (currentTransaction.value && !currentSpan.value) {
            const newName = formatSpanNameForCurrentTransaction(name);
            currentSpan.value = currentTransaction.value?.startSpan(newName, type, options);
            if (currentSpan.value) {
                if (labels) {
                    currentSpan.value.addLabels(labels);
                }
                allSpanNamesUsedInCurrentTransaction.push(newName);
            }
        } else {
            throw new Error(
                'Impossible to add a Span if there is no active transaction or a span already in progress.'
            );
        }
    }

    /**
     * Stops the default transaction span. Allows to add labels before to end the span.
     */
    function stopSpan(labels?: Labels): void {
        if (labels) {
            currentSpan.value?.addLabels(labels);
        }
        currentSpan.value?.end();
        currentSpan.value = undefined;
    }

    /**
     * Ends the current transaction.
     */
    function endTransaction(): void {
        const transaction = currentTransaction.value;

        // set all not ended spans as cancelled
        stopSpan({ status: StatusLabel.CANCELLED });
        Object.keys(additionalSpans).forEach((spanId) => stopSpanWithId(spanId, { status: StatusLabel.CANCELLED }));

        transaction?.end();
        currentTransaction.value = undefined;
    }

    /**
     * Create a temporary Span to simulate a simple event in the transaction like a click
     */
    function sendEvent(name: string, type: string, labels?: Labels, options?: SpanOptions): void {
        const spanId = startSpanWithId(name, type, labels, options);
        // delay the span end to not clash with the start
        setTimeout(() => {
            stopSpanWithId(spanId);
        }, 100);
    }

    /**
     * When configured, the user context is automatically set for all transactions
     */
    function setUserContextWithCode(code: string, user?: UserObject): void {
        apmService?.setUserContext({
            id: code,
            username: user?.username ?? 'anonymous',
            email: user?.email,
        });
    }

    return {
        currentTransaction: readonly(currentTransaction),
        currentSpan: readonly(currentSpan),
        startTransaction,
        endTransaction,
        sendEvent,
        addLabels,
        addMark,
        setUserContextWithCode,
        startSpan,
        stopSpan,
        startSpanWithId,
        stopSpanWithId,
        getSpanById,
    };
}

export type TransactionHandler = (transaction: Transaction) => void;

export function useApmTransactionOnMounted(transactionName: string, transactionType: string) {
    const apmTransactionModule = useApmTransaction();
    const onUnmountedTransactionHandlers: Array<TransactionHandler> = [];
    const onMountedTransactionHandlers: Array<TransactionHandler> = [];

    function addOnUnmountedTransactionHandler(handler: TransactionHandler): void {
        onUnmountedTransactionHandlers.push(handler);
    }

    function addOnMountedTransactionHandler(handler: TransactionHandler): void {
        onMountedTransactionHandlers.push(handler);
    }

    onMounted(() => {
        // never try to start a new transaction if a transaction is already in progress
        if (!currentTransaction.value) {
            const newTransactionRef = apmTransactionModule.startTransaction(transactionName, transactionType);
            const transaction = newTransactionRef.value;
            if (transaction) {
                onMountedTransactionHandlers.forEach((handler) => handler(transaction));
            }
        }
    });

    onBeforeRouteLeave(() => {
        const transaction = currentTransaction.value;
        if (transaction) {
            onUnmountedTransactionHandlers.forEach((handler) => handler(transaction));
            apmTransactionModule.endTransaction();
        }
    });

    return {
        addOnMountedTransactionHandler,
        addOnUnmountedTransactionHandler,
        apmTransactionModule,
    };
}

export default useApmTransaction;
