import TaskStatus from '@/models/TaskStatus';

export default abstract class Task {
    private readonly _name: string;
    private readonly _description: string | undefined;
    private _status: TaskStatus;
    private _onStatusChangeHandlers: Array<(status: TaskStatus) => void>;
    protected _isTaskCompletedCheckFunction: ((...args: unknown[]) => boolean) | undefined;

    protected constructor(
        name: string,
        description: string | undefined = undefined,
        isTaskCompletedCheckFunction: ((...args: unknown[]) => boolean) | undefined = undefined
    ) {
        this._name = name;
        this._description = description;
        this._isTaskCompletedCheckFunction = isTaskCompletedCheckFunction;
        this._status = TaskStatus.IDLE;
        this._onStatusChangeHandlers = [];
    }

    public check(): boolean {
        if (this.isCompleted()) {
            this.changeStatus(TaskStatus.FINISHED);
            return true;
        }
        return false;
    }

    /**
     * Warning: should be used to recover the task state from the api only.
     */
    public forceCheck(): boolean {
        this.changeStatus(TaskStatus.FINISHED);
        return true;
    }

    protected isCompleted(): boolean {
        if (this._isTaskCompletedCheckFunction) {
            return this._isTaskCompletedCheckFunction();
        }
        return false;
    }

    public addOnStatusChangeHandler(handler: (status: TaskStatus) => void): void {
        this._onStatusChangeHandlers.push(handler);
    }

    public start(): void {
        this.changeStatus(TaskStatus.IN_PROGRESS);
    }

    public cancel(): void {
        this.changeStatus(TaskStatus.CANCELLED);
    }

    protected changeStatus(newStatus: TaskStatus): void {
        this._status = newStatus;
        this._onStatusChangeHandlers.forEach((handler) => handler(newStatus));
    }

    // Accessors

    get status(): TaskStatus {
        return this._status;
    }

    get name(): string {
        return this._name;
    }

    get description(): string | undefined {
        return this._description;
    }

    public setIsTaskCompletedCheckFunction(checkFunction: (...args: unknown[]) => boolean): void {
        this._isTaskCompletedCheckFunction = checkFunction;
    }
}
