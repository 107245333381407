import axios, { AxiosError } from 'axios';
import useAxiosLoadingInterceptors from '@/composables/useAxiosLoadingInterceptors';

export interface AppVersionStatus {
    isUpToDate: boolean;
    currentVersion: string;
    packagedVersion: string;
}

export default function useCheckAppVersion() {
    function isAppUpToDate(): Promise<AppVersionStatus> {
        const baseUrl = window.location.origin;
        const versionTrackerFile = '/version_tracker.json';

        useAxiosLoadingInterceptors().addAxiosLoadingInterceptors(axios);

        return axios
            .get(`${baseUrl}${versionTrackerFile}`)
            .then((response) => {
                const versionTracker = response.data;
                const currentVersion = versionTracker.version;
                const packagedVersion = process.env.VUE_APP_VERSION;

                return {
                    isUpToDate: currentVersion === packagedVersion,
                    currentVersion,
                    packagedVersion,
                };
            })
            .catch((error: AxiosError) => {
                throw new Error(`${error.message} (${error.request.responseURL})`);
            });
    }

    return {
        isAppUpToDate,
    };
}
