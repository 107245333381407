import BasicTask from '@/models/Tasks/BasicTask';
import { evaluate } from 'mathjs';
import { formatCalculusForStudent } from '@/models/Tasks/TaskHelper';

export default class CalcTask extends BasicTask {
    private readonly _calculus: string;
    private readonly _formattedCalculus: string;

    public constructor(name: string, description: string | undefined, calculus: string) {
        super(name, description);
        this._calculus = calculus;
        this._formattedCalculus = formatCalculusForStudent(calculus);
        this._expectedResponseValue = `${calculus}=${evaluate(calculus)}`;
    }

    get formattedCalculus(): string {
        return this._formattedCalculus;
    }
}
