import { IonicVue } from '@ionic/vue';
import { createApp } from 'vue';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/style/app.scss';

const app = createApp(App);

app.use(store)
    .use(IonicVue)
    .use(router)
    .use(ApmVuePlugin, {
        router,
        config: {
            serverUrl: process.env.VUE_APP_APM_AGENT_SERVER_URL,
            serviceName: 'apm_agent-1',
            disableInstrumentations: ['page-load', 'history', 'eventtarget', 'click', 'xmlhttprequest', 'fetch'],
            environment: process.env.NODE_ENV,
            eventsLimit: 250, // 250 events per minute max
            transactionSampleRate: 1.0, // force to send all transactions
        },
    })
    .mount('#app');
