import { readonly, ref } from 'vue';

const isLoading = ref(false);

export default function useLoader() {
    const startLoading = () => {
        isLoading.value = true;
    };

    const stopLoading = () => {
        isLoading.value = false;
    };

    return {
        isLoading: readonly(isLoading),
        startLoading,
        stopLoading,
    };
}
