import { readonly, ref } from 'vue';

const elementAdded = ref();
const elementRemoved = ref();
const elementOrderedUp = ref();
const elementOrderedDown = ref();
const elementClick = ref();
const elementBlur = ref();
const elementDrop = ref();

export function useListEvents() {
    function fireElementAdded(element: unknown) {
        elementAdded.value = element;
    }

    function fireElementRemoved(element: unknown) {
        elementRemoved.value = element;
    }

    return {
        elementAdded: readonly(elementAdded),
        elementRemoved: readonly(elementRemoved),
        fireElementAdded,
        fireElementRemoved,
    };
}

export function useOrderedListEvents() {
    const listEvents = useListEvents();

    function fireElementOrderedUp(element: unknown) {
        elementOrderedUp.value = element;
    }

    function fireElementOrderedDown(element: unknown) {
        elementOrderedDown.value = element;
    }

    return {
        ...listEvents,
        elementOrderedUp: readonly(elementOrderedUp),
        elementOrderedDown: readonly(elementOrderedDown),
        fireElementOrderedUp,
        fireElementOrderedDown,
    };
}

export function useClickEvents() {
    function fireElementClick(data: unknown) {
        elementClick.value = data;
    }

    return {
        elementClick: readonly(elementClick),
        fireElementClick,
    };
}

export function useBlurEvents() {
    function fireElementBlur(data: unknown) {
        elementBlur.value = data;
    }

    return {
        elementBlur: readonly(elementBlur),
        fireElementBlur,
    };
}

export function useDropEvents() {
    function firstElementDrop(data: unknown) {
        elementDrop.value = data;
    }

    return {
        elementDrop: readonly(elementDrop),
        firstElementDrop,
    };
}
