import { readonly, ref } from 'vue';
import useLoader from '@/composables/useLoader';

function useRecording() {
    const { startLoading, stopLoading } = useLoader();

    let mediaStream: MediaStream | null;
    let mediaRecorder: MediaRecorder | null;

    let audio: HTMLAudioElement | null;
    const isRecordingPlaying = ref(false);
    const isRecording = ref(false);

    async function initMediaStream(): Promise<MediaStream> {
        try {
            if (!mediaStream) {
                mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            }

            return new Promise<MediaStream>((resolve) => {
                // eslint-disable-next-line
                resolve(mediaStream!);
            });
        } catch (error) {
            // eslint-disable-next-line
            alert("Il y a un problème avec l'autorisation du microphone !");
            return Promise.reject(new Error('Impossible to create MediaStream.'));
        }
    }

    function initMediaRecorder(stream: MediaStream): MediaRecorder {
        if (!mediaRecorder) {
            mediaRecorder = new MediaRecorder(stream);
        }
        return mediaRecorder;
    }

    async function startToRecord(): Promise<void> {
        startLoading();
        await initMediaStream();

        if (!mediaStream) {
            stopLoading();
            return Promise.reject(new Error('The MediaStream is not initialised!'));
        }

        initMediaRecorder(mediaStream);

        if (!mediaRecorder) {
            stopLoading();
            return Promise.reject(new Error('The MediaRecorder is not initialised!'));
        }

        // we create a promise to know exactly when the recording has started
        const startPromise = new Promise<void>((resolve) => {
            const handleStart = () => {
                isRecording.value = true;
                stopLoading();
                resolve();
            };

            // eslint-disable-next-line
            mediaRecorder!.addEventListener('start', handleStart, true);
        });

        // not get stack with the loading in case of an error
        const errorListener = () => {
            isRecording.value = false;
            stopLoading();
        };

        // eslint-disable-next-line
        mediaRecorder!.addEventListener('error', errorListener, true);

        mediaRecorder.start();

        return startPromise;
    }

    async function stopToRecord(): Promise<Blob> {
        if (!mediaRecorder) {
            return Promise.reject(new Error('The MediaRecorder is not initialised!'));
        }

        if (mediaRecorder.state === 'inactive') {
            return Promise.reject(new Error('The MediaRecorder is already stopped!'));
        }

        // we create a promise that will provide the recorded data
        const dataPromise: Promise<Blob> = new Promise((resolve) => {
            const handleData = (e: BlobEvent) => {
                resolve(e.data);
            };

            // eslint-disable-next-line
            mediaRecorder!.addEventListener('dataavailable', handleData, true);
        });

        const disposeRecording = () => {
            isRecording.value = false;

            if (mediaStream) {
                mediaStream.getAudioTracks().forEach((mediaStreamTrack: MediaStreamTrack) => mediaStreamTrack.stop());
                mediaStream = null;
            }

            mediaRecorder = null;
        };

        // eslint-disable-next-line
        mediaRecorder!.addEventListener('stop', disposeRecording, true);

        mediaRecorder.stop();

        return dataPromise;
    }

    function getBlobUrl(blobRecord: Blob): string {
        return URL.createObjectURL(blobRecord);
    }

    async function listenToRecord(blobRecord: Blob): Promise<void> {
        const url = getBlobUrl(blobRecord);

        if (!audio) {
            audio = document.createElement('audio');
            document.body.appendChild(audio);
        }

        audio.src = url;

        // we add listener for the audio end event to know when an audio finished
        audio.addEventListener(
            'ended',
            () => {
                isRecordingPlaying.value = false;
            },
            true
        );

        return audio.play().then(() => {
            // when the audio is playing we set the variable to signal who uses this composable
            isRecordingPlaying.value = true;
        });
    }

    function stopListenToRecord() {
        audio?.pause();
        isRecordingPlaying.value = false;
        if (audio) {
            document.body.removeChild(audio);
            audio = null;
        }
    }

    return {
        startToRecord,
        stopToRecord,
        getBlobUrl,
        listenToRecord,
        stopListenToRecord,
        isRecordingPlaying: readonly(isRecordingPlaying),
        isRecording: readonly(isRecording),
    };
}

export default useRecording();
