import TaskType from '@/models/TaskType';
import SessionStatus from '@/models/SessionStatus';
import TaskConfig from '@/interfaces/TaskConfig';
import { v4 as uuidv4 } from 'uuid';

export default class FakeConfig {
    public config(): TaskConfig {
        return {
            meta: {
                name: 'Fake Config',
                description: 'This is a fake config for testing',
            },
            series: [
                {
                    name: 'PEM',
                    tasks: [
                        {
                            name: 'pem-ide',
                            type: TaskType.BASIC_TASK,
                            description: 'LOGIN page',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'pem-son',
                            type: TaskType.BASIC_TASK,
                            description: 'SON Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'pem-enr',
                            type: TaskType.BASIC_TASK,
                            description: 'ENR Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'pem-dra',
                            type: TaskType.BASIC_TASK,
                            description: 'DRA Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'pem-cal',
                            type: TaskType.CALC_TASK,
                            calculus: '12/3',
                            description: 'CAL Task',
                        },
                    ],
                },
                {
                    name: 'MAT',
                    tasks: [
                        {
                            name: 'mat-div',
                            type: TaskType.BASIC_TASK,
                            description: 'DIV Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'mat-flo',
                            type: TaskType.BASIC_TASK,
                            description: 'FLO Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'mat-ecr',
                            type: TaskType.BASIC_TASK,
                            description: 'ECR Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'mat-sym',
                            type: TaskType.BASIC_TASK,
                            description: 'SYM Task',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'mat-tax',
                            type: TaskType.BASIC_TASK,
                            description: 'TAX Task',
                            expectedResponseValue: null,
                        },
                    ],
                },
                {
                    name: 'ACO',
                    tasks: [
                        {
                            name: 'aco-anc-1',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Ancrage task 1',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-anc-2',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Ancrage task 2',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-fet-1',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Fet 1',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-fet-2',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Fet 2',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-fet-3',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Fet 3',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-fet-4',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Fet 4',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-fet-5',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Fet 5',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-nve-1',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 1',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-nve-2',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 2',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-nve-3',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 3',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-par-1',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 1',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-par-2',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 2',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-par-3',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 3',
                            expectedResponseValue: null,
                        },
                        {
                            name: 'aco-par-4',
                            type: TaskType.BASIC_TASK,
                            description: 'ACO Nve 4',
                            expectedResponseValue: null,
                        },
                    ],
                },
                {
                    name: 'END',
                    tasks: [
                        {
                            name: 'end-task',
                            type: TaskType.BASIC_TASK,
                            description: 'END task',
                            expectedResponseValue: null,
                        },
                    ],
                },
            ],
            current_task: {
                name: 'aco-anc-1',
                type: TaskType.BASIC_TASK,
                description: 'Fake Task',
                expectedResponseValue: null,
                series: 'ACO',
            },
            session_id: uuidv4(),
            session_status: SessionStatus.STARTED,
            student_id: 1,
        };
    }
}
