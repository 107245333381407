// eslint-disable-next-line no-shadow
enum EventType {
    WRONG_INPUT = 'WRONG_INPUT',
    WAITING = 'WAITING',
    PLAYING_INSTRUCTION_SOUND = 'PLAYING_INSTRUCTION_SOUND',
    RECORDING = 'RECORDING',
    INPUT = 'INPUT',
    CLICK = 'CLICK',
    POP = 'POP',
    DROP = 'DROP',
    SESSION_STATUS = 'SESSION_STATUS',
}

export default EventType;
