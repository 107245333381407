import { ref, readonly } from 'vue';
import { OPERATORS, DIGITS } from '@/shared/constants';

const clearOnNextDigit = ref(false);
const fullMemory = ref('');
const memory = ref('');
const error = ref(false);
// Multiple
const fullMemoryArray = ref<string[]>([]);
const deletedFromFullMemory = ref('');
function useCalculate() {
    function isOperator(string: string) {
        return OPERATORS.includes(string);
    }

    function isDigit(string: string) {
        return DIGITS.includes(string);
    }

    function lastCharIsOperator(string: string) {
        const stringNormalized = string.replace(/\s/g, '');
        return isOperator(stringNormalized[stringNormalized.length - 1]);
    }

    function eraseLast() {
        if (!memory.value.length) return;

        memory.value = memory.value.slice(0, memory.value.length - 1);
        clearOnNextDigit.value = false;
    }

    function clear() {
        memory.value = '';
        fullMemory.value = '';
        error.value = false;
    }

    function clearOneFromFullMemoryArray(index: number) {
        clear();
        const deleted = fullMemoryArray.value[index];
        fullMemoryArray.value.splice(index, 1);
        deletedFromFullMemory.value = deleted;
    }

    function addDigit(digit: string) {
        if (!isDigit(digit)) {
            throw new Error('Invalid param, is not a valid digit');
        }

        if (memory.value.length >= 15) {
            return;
        }

        const lastDigit = memory.value[memory.value.length - 1];

        if (lastDigit === '.' && digit === '.') return;
        if (lastDigit === '0' && memory.value.length === 1) clear();
        if (clearOnNextDigit.value) clear();
        if ((!memory.value || lastCharIsOperator(memory.value)) && digit === '.') memory.value += '0';

        clearOnNextDigit.value = false;
        memory.value += `${digit}`;
    }

    function addOperator(operator: string) {
        if (!isOperator(operator)) {
            throw new Error('Invalid param, is not a valid operator');
        }

        if (memory.value.length >= 15) {
            return;
        }

        if (!memory.value) return;
        if (lastCharIsOperator(memory.value)) return;

        // Prevent adding operator if there is already one
        if (OPERATORS.some((operatorToCheck) => memory.value.includes(operatorToCheck))) {
            return;
        }

        clearOnNextDigit.value = false;
        memory.value += `${operator}`;
    }

    function calculateResult() {
        if (!memory.value) return;

        if (lastCharIsOperator(memory.value)) {
            return;
        }

        if (!OPERATORS.some((operator) => memory.value.includes(operator))) {
            return;
        }

        try {
            fullMemory.value = '';
            const mathExpression = memory.value.replace(/\b0*((\d+\.\d+|\d+))\b/g, '$1'); // remove octal numeric
            fullMemory.value = `${mathExpression}=`;
            // eslint-disable-next-line no-eval
            memory.value = `${eval(mathExpression)}`;
            const memorySplit = memory.value.split('.');

            let newMemory = '';
            if (memorySplit.length > 1 && memorySplit[1].length > 3) {
                newMemory = `${memorySplit[0]},${memorySplit[1][0]}${memorySplit[1][1]}${memorySplit[1][2]}...`;
            } else {
                newMemory = memory.value.replaceAll('.', ',');
            }
            fullMemory.value = fullMemory.value.replaceAll('.', ',');
            fullMemory.value += newMemory;

            // clear the calculator screen when the result calculus is submitted
            memory.value = '';

            // adding all the computations to the memory array
            fullMemoryArray.value.push(fullMemory.value);
        } catch (_) {
            error.value = true;
            memory.value = '';
        } finally {
            clearOnNextDigit.value = true;
        }
    }

    function resetCalculator() {
        clearOnNextDigit.value = false;
        fullMemory.value = '';
        memory.value = '';
        error.value = false;
        fullMemoryArray.value = [];
        deletedFromFullMemory.value = '';
    }

    return {
        memory: readonly(memory),
        error: readonly(error),
        fullMemory: readonly(fullMemory),
        fullMemoryArray: readonly(fullMemoryArray),
        deletedFromFullMemory: readonly(deletedFromFullMemory),
        addDigit,
        addOperator,
        calculateResult,
        eraseLast,
        clear,
        clearOneFromFullMemoryArray,
        resetCalculator,
    };
}

export default useCalculate;
