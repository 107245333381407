import useDialog from '@/composables/useDialog';
import useCheckAppVersion, { AppVersionStatus } from '@/composables/useCheckAppVersion';
import { readonly, ref } from 'vue';

const { createNewDialog, openModal } = useDialog();
const appMustUpdateModal = createNewDialog();
const errorAppVersionCheckModal = createNewDialog();

const currentVersion = ref('');
const packagedVersion = ref('');
const errorAppVersionCheckMessage = ref('');

export default function useCheckAppVersionModals() {
    function showModalIfAppMustUpdate(): Promise<AppVersionStatus> {
        const { isAppUpToDate } = useCheckAppVersion();

        return isAppUpToDate()
            .then((appVersionStatus: AppVersionStatus) => {
                if (!appVersionStatus.isUpToDate) {
                    currentVersion.value = appVersionStatus.currentVersion;
                    packagedVersion.value = appVersionStatus.packagedVersion;
                    openModal(appMustUpdateModal);
                }

                return appVersionStatus;
            })
            .catch((error) => {
                errorAppVersionCheckMessage.value = error.message;
                openModal(errorAppVersionCheckModal);

                throw error;
            });
    }

    return {
        showModalIfAppMustUpdate,
        appMustUpdateModal,
        errorAppVersionCheckModal,
        currentVersion: readonly(currentVersion),
        packagedVersion: readonly(packagedVersion),
        errorAppVersionCheckMessage: readonly(errorAppVersionCheckMessage),
    };
}
