import { ref } from 'vue';
import type { Ref } from 'vue';

type DialogList = Array<Ref<boolean>>;
const dialogs: DialogList = [];
const onOpenModalHandlers: Array<(modalKey: number) => void> = [];

function useDialog() {
    function closeAllModals(): void {
        dialogs.forEach((booleanValue, index) => {
            dialogs[index].value = false;
        });
    }

    function closeModal(key: number): void {
        dialogs[key].value = false;
    }

    function openModal(key: number, closeOtherModals = true): void {
        if (closeOtherModals) {
            closeAllModals();
        }
        onOpenModalHandlers.forEach((handler) => handler(key));
        dialogs[key].value = true;
    }

    function createNewDialog(): number {
        dialogs.push(ref(false));
        return dialogs.length - 1;
    }

    function addOnOpenModalHandler(handler: (modalKey: number) => void): void {
        onOpenModalHandlers.push(handler);
    }

    return { dialogs, openModal, closeModal, closeAllModals, createNewDialog, addOnOpenModalHandler };
}

export default useDialog;
