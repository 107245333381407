import { AxiosInstance } from 'axios';
import useLoader from '@/composables/useLoader';

const { startLoading, stopLoading } = useLoader();

export default function useAxiosLoadingInterceptors() {
    function addAxiosLoadingInterceptors(axiosInstance: AxiosInstance) {
        axiosInstance.interceptors.request.use((config) => {
            startLoading();
            return config;
        });

        axiosInstance.interceptors.response.use(
            (response) => {
                stopLoading();
                return response;
            },
            (error) => {
                stopLoading();
                return Promise.reject(error);
            }
        );
    }

    return {
        addAxiosLoadingInterceptors,
    };
}
