// eslint-disable-next-line no-shadow
enum SessionStatus {
    SCHEDULED = 'SCHEDULED',
    READY = 'READY',
    WAITING = 'WAITING',
    STARTED = 'STARTED',
    PAUSED = 'PAUSED',
    ENDED = 'ENDED',
}

export default SessionStatus;
