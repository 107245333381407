import Serie from '@/models/Serie';
import Task from '@/models/Task';

export default class Session {
    private readonly _id: string;
    private _currentSerieIndex: number;
    private readonly _series: Array<Serie>;

    public constructor(id: string, series: Array<Serie>) {
        this._id = id;
        this._currentSerieIndex = -1;
        this._series = series;
    }

    public isFinished(): boolean {
        return this._series.filter((series: Serie) => !series.isFinished()).length === 0;
    }

    public selectNextSerie(): Serie | null {
        const currentSerie = this._series[this._currentSerieIndex] ?? null;
        if (currentSerie && !currentSerie.isFinished()) {
            throw new Error('Impossible to select the next serie until the current is finished');
        }
        this._currentSerieIndex += 1;
        return this.getCurrentSerie();
    }

    public getCurrentSerie(): Serie | null {
        if (this._currentSerieIndex < 0) {
            this._currentSerieIndex += 1;
        }
        return this._series[this._currentSerieIndex] ?? null;
    }

    public getCurrentTask(): Task | null {
        return this.getCurrentSerie()?.getCurrentTask() ?? null;
    }

    // Accessors

    get id(): string {
        return this._id;
    }

    get series(): Array<Serie> {
        return this._series;
    }
}
