import axios, { AxiosInstance } from 'axios';
import useAxiosLoadingInterceptors from '@/composables/useAxiosLoadingInterceptors';

export default function useAxios(): AxiosInstance {
    const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
        withCredentials: true,
    });

    useAxiosLoadingInterceptors().addAxiosLoadingInterceptors(axiosInstance);

    return axiosInstance;
}
