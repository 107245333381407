import Task from '@/models/Task';

export default class BasicTask extends Task {
    protected _expectedResponseValue: string | undefined;
    private _responseValue: string | undefined;

    public constructor(
        name: string,
        description: string | undefined = undefined,
        expectedResponseValue: string | undefined = undefined,
        isTaskCompletedCheckFunction: ((...args: unknown[]) => boolean) | undefined = undefined
    ) {
        super(name, description, isTaskCompletedCheckFunction);
        this._expectedResponseValue = expectedResponseValue;
    }

    protected isCompleted(): boolean {
        if (this._isTaskCompletedCheckFunction) {
            return this._isTaskCompletedCheckFunction(this._responseValue);
        }
        return this._responseValue === this._expectedResponseValue;
    }

    set responseValue(value: string | undefined) {
        this._responseValue = value;
    }

    get responseValue(): string | undefined {
        return this._responseValue;
    }

    get expectedResponseValue(): string | undefined {
        return this._expectedResponseValue;
    }
}
