// eslint-disable-next-line import/prefer-default-export
export function generateRandomCalculus(): string {
    const randomOperator = () => {
        const randomIndex = Math.trunc(Math.random() * 10) % 4;
        const operators = ['*', '/', '-', '+'];
        return operators[randomIndex];
    };
    const randomNumber1 = Math.trunc(Math.random() * 10);
    const randomNumber2 = Math.trunc(Math.random() * 10);
    return `${randomNumber1}${randomOperator()}${randomNumber2}`;
}

export function formatCalculusForStudent(calculus: string): string {
    return calculus.replace('*', ' x ').replace('/', ' : ').replace('-', ' - ').replace('+', ' + ').replace('=', ' = ');
}
