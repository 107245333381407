import { computed, reactive, ref } from 'vue';
import { useDropEvents, useListEvents } from '@/composables/useEventBus';

export interface Coordinates {
    x: number;
    y: number;
}

// the identifier of the correct box answer
const correctBoxIndex = ref<number>();
const answersInitial = reactive<string[]>([]);
const choicesInitial = reactive<string[]>([]);
const choicesConstruct = reactive<string[]>([]);
const isChoicesConstructFull = computed(() => !choicesConstruct.includes(''));
const answerDrag = ref();
const allResults = reactive<string[]>([]);
const answerInit = reactive<string[][]>([]);

// coordinates of the last drop zone where the drop box have been dragged
const lastDropZoneCenterCoordinates = ref<Coordinates>();

const resetDragBoxPositionHandlers: Array<() => void> = [];

const listEvents = useListEvents();
const dropEvents = useDropEvents();

export default function useDraggableBox() {
    function resetDraggableBox(): void {
        correctBoxIndex.value = undefined;
        answersInitial.splice(0);
        choicesInitial.splice(0);
        choicesConstruct.splice(0);
        answerDrag.value = undefined;
        allResults.splice(0);
        answerInit.splice(0);
        resetDragBoxPositionHandlers.splice(0);
    }
    function resetDragBoxPosition(): void {
        resetDragBoxPositionHandlers.forEach((handler) => handler());
    }

    function addResetDragBoxPositionHandler(handler: () => void): number {
        return resetDragBoxPositionHandlers.push(handler);
    }

    const newInit = (text: string, handler: number) => {
        return answerInit.push([text, handler.toString()]);
    };

    const dragStartAnswer = (text: string, id: number) => {
        answerDrag.value = { text, id };
    };

    const newEntryAnswer = (text: string, index: number) => {
        choicesConstruct[choicesConstruct.indexOf(`${answerDrag.value.text}${answerDrag.value.id}`)] = '';
        if (answersInitial[index] !== `${answerDrag.value.text}${answerDrag.value.id}`) {
            answerInit.forEach((answer) => {
                if (answer[0] + answer[1] === `${answerDrag.value.text}${answerDrag.value.id}`) {
                    resetDragBoxPositionHandlers[Number(answer[1]) - 1]();
                }
            });
        }
    };

    const newEntryChoice = (boxId: number) => {
        if (choicesConstruct.includes(`${answerDrag.value.text}${answerDrag.value.id}`)) {
            choicesConstruct[choicesConstruct.indexOf(`${answerDrag.value.text}${answerDrag.value.id}`)] = '';
        }
        if (choicesConstruct[boxId] !== '') {
            answerInit.forEach((answer) => {
                if (answer[0] + answer[1] === choicesConstruct[boxId]) {
                    resetDragBoxPositionHandlers[Number(answer[1]) - 1]();
                }
            });
        }
        choicesConstruct[boxId] = `${answerDrag.value.text}${answerDrag.value.id}`;
        dropEvents.firstElementDrop({
            boxId,
            choice: choicesConstruct[boxId],
            answer: answerDrag.value.text,
        });
    };

    const answerString = ref('');

    const saveAnswer = () => {
        choicesConstruct.forEach((choice, index) => {
            answerString.value += choice[0];
            choicesConstruct[index] = '';
        });
        let answer = '';
        if (!allResults.includes(answerString.value)) {
            allResults.push(answerString.value);
            answer = answerString.value;
        }
        answerString.value = '';
        resetDragBoxPosition();
        listEvents.fireElementAdded(answer);
        return answer;
    };

    return {
        resetDraggableBox,
        correctBoxIndex,
        lastDropZoneCenterCoordinates,
        resetDragBoxPosition,
        addResetDragBoxPositionHandler,
        answersInitial,
        choicesInitial,
        choicesConstruct,
        isChoicesConstructFull,
        answerDrag,
        saveAnswer,
        dragStartAnswer,
        newEntryAnswer,
        newEntryChoice,
        allResults,
        newInit,
        answerInit,
    };
}
