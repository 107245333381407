import { ConnectionStatus, Network } from '@capacitor/network';
import { readonly, ref } from 'vue';

const networkStatus = ref<ConnectionStatus>({ connected: false, connectionType: 'none' });
export default function useNetworkStatus() {
    function addNetworkStatusListener() {
        Network.removeAllListeners().then(() => {
            Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
                networkStatus.value = status;
            });
        });
    }

    return {
        addNetworkStatusListener,
        networkStatus: readonly(networkStatus),
    };
}
